<sm-dialog-template [header]="mainTitle()"
                    [headerClass]="'fw-normal no-uppercase'"
                    iconClass="al-ico-bullhorn"
                    [closeOnX]="true"
>
  <div class="tip-container" smClickStopPropagation>
    @if (changes()[changeIndex()].image) {
      <div
        class="tip-image"
        [class.full-width]="!changes()[changeIndex()].content"
        [style.background-image]="'url(' + changes()[changeIndex()].image + ')'"></div>
    }
    @if (changes()[changeIndex()].content) {
      <div class="tip-content" [class.full-width]="!changes()[changeIndex()].image">
        <div class="content-title">{{changes()[changeIndex()].title}}</div>
      <div
        class="tip-content"
        [innerHTML]="changes()[changeIndex()].content | purify"></div>
      </div>
    }
  </div>
</sm-dialog-template>

<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="true">DISMISS</button>
</div>
