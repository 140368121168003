<sm-update-notifier #update
  [availableUpdates]="(updatesAvailable$ | async)"
  [currentUser]="$any(currentUser())"
  [dismissedVersion]="serverUpdatesService.lastDismissedVersion"
  (versionDismissed)="versionDismissed($event)"
></sm-update-notifier>
<sm-color-picker-wrapper id="color-picker-outlet"></sm-color-picker-wrapper>
<sm-server-notification-dialog-container></sm-server-notification-dialog-container>
<sm-spinner></sm-spinner>
<div class="root-container">
  @if (currentUser()) {
    <sm-side-nav></sm-side-nav>
  }
  <div class="app-container" [class.login-page]="!currentUser()" [class.notifier-open]="update?.active()">
    @if (currentUser()) {
      <sm-header [isLogin]="loginContext | ngrxPush" [isShareMode]="isSharedAndNotOwner()"></sm-header>
    }
    <router-outlet class="main-router"></router-outlet>
  </div>
</div>
<notifier-container></notifier-container>
